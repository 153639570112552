import React from 'react'
import Helmet from 'react-helmet'
import Reagan from '../assets/images/reagan.jpg'
import Layout from '../components/layout'

const about = () => (
  <Layout>
    <div id="main">
      <section id="two">
        <img src={Reagan} alt="Artist Reagan Deming Headshot" />

        <h1>About the Artist</h1>
        <p>
          I am Reagan Deming, a North Texas-based artist and muralist working
          out of my home studio, your home or business, or basically, anywhere
          you need. I create the mural you want, where you want it, and am happy
          to express my own artistic voice or help you find yours. I also offer
          custom art, reproductions, the ever-popular pet portrait to
          immortalize a Fluffy or Fido near you, and paint parties. Safe for all
          ages, my paint parties include a customized painting of your choice,
          all art supplies, set up and takedown, sound system needs, and my
          quirky personality. I guide you and your guests through simple,
          easy-to-follow, and unrushed steps, so you feel like accomplished
          artists with a memorable experience and a keepsake to take home. I am
          willing to travel to you or help you find a space that fits your
          needs.
        </p>
        <p>
          I am a homeschooling mother married to my high school sweetheart with
          an obsession for good books, Dungeons and Dragons, and eclectic music
          choices. I have a degree in music composition from the University of
          Texas at Arlington and will someday use that degree. But, for right
          now, I’m happy painting.
        </p>
        <ul className="actions">
          <li>
            <a href="/" className="button">
              Back to Home Page
            </a>
          </li>
        </ul>
      </section>
    </div>
  </Layout>
)

export default about
